<template>
  <header class="w-full relative bg-brand-grey-700">
    <!--  HeroBanner content  -->
    <div class="container flex sm:py-4">
      <div
        v-if="variation === 'imageonleft'"
        class="hidden lg:block lg:w-1/2"
      ></div>
      <div
        class="hero-content flex flex-col items-stretch w-full relative z-10 lg:w-1/2 lg:items-start lg:py-24 lg:min-h-360"
        :class="classList.content"
      >
        <!-- Headings -->
        <div
          v-if="variation !== 'fullimage'"
          class="flex"
          :class="
            titleOrder === 'subtitlefirst' ? 'flex-col-reverse' : 'flex-col'
          "
        >
          <h2>
            {{ title }}
          </h2>
          <h3 :class="titleOrder === 'subtitlefirst' ? 'mt-0' : ''">
            {{ subtitle }}
          </h3>
        </div>

        <!-- CTAs -->
        <div
          v-if="variation !== 'fullimage' && ctas"
          class="flex my-5 md:mb-0 gap-4"
        >
          <A11yButton
            v-for="(cta, i) in ctas"
            :key="i"
            :color="getCtaColour(cta)"
            :component="cta.component"
            :href="cta.href"
            :icon="cta.icon"
            :linktype="cta.linktype"
            :variant="cta.type"
            class="w-full sm:w-auto"
          >
            <Icon
              v-if="cta.icon"
              class="text-2xl pr-2"
              :name="getCtaIcon(cta)"
            ></Icon>
            <span>{{ cta.text }}</span>
          </A11yButton>
        </div>
      </div>
    </div>
    <!--  HeroBanner img  -->
    <div>
      <ImageSrcset
        :source="getImgSrc(image?.src)"
        :alt="image?.alt"
        :ext="ext"
        class="lg:absolute w-full top-0 h-full object-cover object-center"
        :class="classList.img"
      />
    </div>
  </header>
</template>

<script setup lang="ts">
import useHelpers from '~/composables/useHelpers'

/**
 * Heading, subtitle and CTAs are all optional and are displayed if present
 * Variation prop controls position of text as follows
 *    'imageonright'
 *    'imageonleft'
 *    'fullimage' = image only, hiding headings,
 *    'textonimage' = wide-screen image, text on the left
 */

interface HeroBannerProps {
  variation?: string
  titleOrder?: string
  title?: string
  subtitle?: string
  ext?: string
  image?: Image
  ctas?: Cta[]
}

interface Image {
  src?: string
  alt?: string
}

interface Cta {
  text?: string
  href?: string
  icon?: Icon
  type?: string
  component?: string
  linktype?: string
  color?: string
}

interface Icon {
  name?: string
}

interface ClassList {
  img?: string
  content?: string
}

const props = withDefaults(defineProps<HeroBannerProps>(), {
  variation: '',
  titleOrder: '',
  title: '',
  subtitle: '',
  image: () => ({
    src: '',
    alt: ''
  }),
  ctas: () => [],
  ext: 'jpg'
})

const { getImgSrc } = useHelpers()

const classList = computed(() => {
  let classes: ClassList = {}
  if (['left', 'imageonright'].includes(props.variation)) {
    classes.img = 'lg:w-1/2 right-0'
    classes.content = 'lg:pr-20 pt-6'
  } else if (props.variation === 'textonimage') {
    classes.content = 'lg:pr-20 pt-6'
  } else if (['right', 'imageonleft'].includes(props.variation)) {
    classes.img = 'lg:w-1/2 left-0'
    classes.content = 'lg:pl-20 pt-6'
  } else {
    classes.img = 'right-0 object-right min-h-280'
  }
  return classes
})

function getCtaIcon(cta: Cta) {
  if (cta?.icon) {
    return cta.icon.name ? cta.icon.name : (cta.icon as string)
  }
  return ''
}

function getCtaColour(cta: Cta) {
  return cta?.color ? `${cta.color}-500` : 'blue-500'
}
</script>

<style lang="postcss" scoped>
h3 {
  @apply text-white text-xl font-normal leading-7 pt-4;
  @screen lg {
    @apply pt-6 mb-4;
  }
}
</style>
