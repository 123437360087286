<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <section>
    <fetch-loading v-if="pending" />
    <fetch-error v-else-if="error" :error="error" />
    <div v-else class="bg-gray-100">
      <!-- CSR only to prevent duplicate element bug -->
      <section class="relative flex flex-col banner">
        <!-- Search bar section -->
        <div
          class="w-full max-w-screen-2xl mx-auto bg-white shadow pb-8 md:pb-10 xl:pb-12"
        >
          <div class="relative flex flex-col mb-8">
            <div
              class="aspect-w-16 aspect-h-7 sm:aspect-h-6 md:aspect-h-4"
              :class="`md:border-b-5 border-brand-${banner.subtitleColour}-200`"
            >
              <ImageSrcset
                v-if="banner && banner.heroImage && banner.heroImage.src"
                :source="banner.heroImage.src"
                class="w-full object-cover object-right lg:object-center"
                :alt="banner.heroImage.alt"
              />
            </div>
            <div class="z-10 flex flex-col justify-center md:absolute inset-0">
              <div
                class="bg-brand-grey-700 md:bg-charcoal-08 text-white md:w-2/3 xl:w-1/2 p-4 md:p-8 md:pl-24 xl:pl-48 space-y-4"
              >
                <h2>
                  <span v-html="banner.title"></span>
                </h2>
                <p
                  class="text-base font-medium md:text-lg xl:text-xl"
                  v-html="banner.subtitle"
                ></p>
              </div>
              <div
                class="bg-gray-100 md:bg-transparent md:z-10 md:absolute inset-x-0 bottom-0 py-8 px-4 md:py-0 md:px-24 xl:px-48 md:-mb-5"
              >
                <h2
                  class="text-center font-medium text-2xl lg:text-3xl xl:text-4xl md:hidden mb-4"
                >
                  Course search
                </h2>
                <ClientOnly>
                  <SearchBar
                    id="homePageSearch"
                    v-model:search-param="keyword"
                    show-button
                    :location-search="coursesNearMeFlag"
                    placeholder-text="Search for courses or other content"
                    label-text="Search for courses or other content"
                    @search="
                      $router.push({
                        name: 'course-search',
                        query: { keyword, ...locationQuery }
                      })
                    "
                  />
                </ClientOnly>
              </div>
            </div>
          </div>
          <div class="px-4 md:px-12 xl:px-24 py-8 md:py-10 xl:py-12">
            <h2
              class="text-center mb-6 md:mb-8 xl:mb-10 lg:pt-4 font-medium text-2xl lg:text-3xl xl:text-4xl"
              aria-label="{{ popularCourseAreas?.title }}"
              tabindex="0"
            >
              {{ popularCourseAreas?.title }}
            </h2>
            <ul class="grid grid-cols-2 lg:grid-cols-4 grid-spacing">
              <li
                v-for="(courseArea, i) in popularCourseAreaList"
                :key="i"
                class="text-center"
              >
                <NuxtLink
                  :to="courseArea.url"
                  class="group flex flex-col align-center no-underline hover:text-brand-blue-500 hover:underline"
                >
                  <Icon
                    v-if="courseArea.icon"
                    :name="courseArea.icon"
                    class="text-2xl md:text-3xl mx-auto text-brand-grey-400"
                  />
                  <span class="text-base md:text-lg">{{
                    courseArea.title
                  }}</span></NuxtLink
                >
              </li>
            </ul>
            <div class="mt-8 mb:mt-10 xl:mt-12 text-center">
              <A11yButton
                component="nuxt-link"
                to="course-areas"
                class="border"
                variant="primary"
                >{{ popularCourseAreas?.buttonText }}
                <Icon name="IconChevronRight" class="text-2xl"
              /></A11yButton>
            </div>
          </div>
        </div>
      </section>

      <!--- interested section --->
      <div class="container py-8 mb:py-12 xl:py-24">
        <h2
          class="text-center mb-6 md:mb-10 xl:mb-16 font-medium text-2xl lg:text-3xl xl:text-4xl"
          aria-label="I'm interested in"
          tabindex="0"
        >
          I'm interested in
        </h2>
        <tafe-tiles :tiles="interestInTiles" :columns="4" />
      </div>

      <!--- Promotions section --->
      <Promotions v-if="promotions" :promotions="promotions" />

      <!--- Why 秀色直播section --->
      <div v-if="whyTafeNsw" class="container py-8 mb:py-12 xl:py-24">
        <h2
          class="text-center mb-6 md:mb-10 xl:mb-16 font-medium text-2xl lg:text-3xl xl:text-4xl"
          aria-label="{{ whyTafeNsw.title }}"
          tabindex="0"
        >
          {{ whyTafeNsw.title }}
        </h2>
        <div class="grid grid-cols-1 lg:grid-cols-4 gap-6 xl-gap-10">
          <div
            class="copy text-lg xl:text-xl"
            v-html="$md.render(whyTafeNsw.description)"
          ></div>
          <div class="lg:col-span-3">
            <TafeTiles :tiles="whyTafeNsw.tiles" :columns="3" />
            <div
              class="text-xs mt-4 copy"
              v-html="$md.render(whyTafeNsw.terms)"
            ></div>
          </div>
        </div>
      </div>

      <div
        v-if="partnerPrograms && partnerPrograms.length > 0"
        class="bg-gray-200 py-8 mb:py-12 xl:py-24"
      >
        <latest-partners-list class="container" :partners="partnerPrograms" />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useHomepageStore } from '~~/stores/homepage'
import { useUserStore } from '~~/stores/user'
import { useSiteConfigStore } from '~~/stores/siteConfig'

const nuxtApp = useNuxtApp()

const homepageStore = useHomepageStore()
const userStore = useUserStore()
const siteConfigStore = useSiteConfigStore()

const { structuredData } = useStructuredData()

const interestInTiles = [
  {
    title: 'Short\n courses',
    icon: 'IconShortCourses',
    color: 'brand-ochre-100',
    cta: 'View all short courses',
    href: '/short-courses'
  },
  {
    title: 'Degree\n courses',
    icon: 'IconDegreeCourses',
    color: 'brand-teal-100',
    cta: 'View all degree courses',
    href: '/degrees'
  },
  {
    title: 'Online\n courses',
    icon: 'IconOnlineCourses',
    color: 'brand-lavender-100',
    cta: 'View all online courses',
    href: '/digital/courses'
  },
  {
    title: 'Help finding\n courses',
    icon: 'IconFindingCourses',
    color: 'brand-yellow-100',
    cta: 'Take our quiz',
    href: '/study-seeker/start'
  }
]

const keyword = ref('')

const { pending, error } = await useAsyncData(() =>
  homepageStore.fetch().then(() => true)
)

const coursesNearMeFlag = computed(() => {
  return siteConfigStore.config.featureFlags?.coursesNearMe || undefined
})

const locationQuery = computed(() => {
  if (userStore.location.loc === 'TAFE Digital (online)') {
    return {
      dy: 1
    }
  } else if (userStore.location.loc && userStore.location.lat) {
    return {
      lat: userStore.location.lat,
      lng: userStore.location.lng,
      loc: userStore.location.loc,
      rad: userStore.hasGeolocation
        ? useConstants().locationFilterRadius
        : useConstants().locationSearchRadius
    }
  } else return null
})

const banner = computed(
  () =>
    homepageStore.banner ?? {
      title: '',
      subtitle: '',
      subtitleColour: 'apricot',
      heroImage: {
        src: '/images/banner-images/banner-default',
        alt: 'A woman making a dress.'
      }
    }
)

const socialPreviewImageUrl = computed(() => {
  return `${banner.value.heroImage.src}-xl.jpg`
})

nuxtApp.runWithContext(() => {
  useHead({
    title:
      '秀色直播- Choose from hundreds of courses. Explore. Enquire. Enrol.',
    script: [
      {
        type: 'application/ld+json',
        innerHTML: JSON.stringify(structuredData.value)
      }
    ],
    meta: [
      {
        property: 'og:image',
        content: socialPreviewImageUrl.value
      },
      {
        property: 'og:image:secure_url',
        content: socialPreviewImageUrl.value
      },
      { property: 'og:image:width', content: '1263' },
      { property: 'twitter:card', content: 'summary_large_image' }
    ]
  })
})

const popularCourseAreaList = computed(() => {
  return homepageStore.popularCourseAreaList
})

const popularCourseAreas = computed(() => {
  return homepageStore.popularCourseAreas
})

const promotions = computed(() => {
  return homepageStore.promotions
})

const whyTafeNsw = computed(() => {
  return homepageStore.whyTafeNsw
})

const partnerPrograms = computed(() => {
  return homepageStore.partnerPrograms
})
</script>

<style lang="postcss">
.banner p > a {
  @apply text-white;
}
#homePageSearch {
  padding-left: 1rem !important;
}
#homePageSearch + label > .tf-icon {
  display: none;
}
</style>
